<template>
  <div class="animated fadeIn">
  </div>
</template>

<script>

export default {
  name: 'Inicio',
  components: {
  },
  data: function () {
    return {
    }
  },
  methods: {
  }
}
</script>

<style>
</style>
